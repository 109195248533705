<template>
  <Preloader v-if="loading" />

  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-2 w-full">
      <div
        class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between"
      >
        <div>
          <p class="uppercase text-xs text-gray-700 font-semibold">overview</p>
          <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
            Dashboard
          </h1>
        </div>
        <div class="flex gap-2"></div>
      </div>
    </div>

    <!-- grid wrapper card -->
    <div
      class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="rounded-full w-14 h-14 text-lg p-3 text-orange-600 mx-auto"
          >
            <font-awesome-icon
              :icon="['fass', 'battery-three-quarters']"
              style="color: #25d041"
            />
            <span class="text-white p-3 max-w-sm flex items-center ml-20 mb-28"
              >{{ onlineDeviceCount }}
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            Active Devices
          </p>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto"
          >
            <font-awesome-icon
              :icon="['fas', 'battery-empty']"
              style="color: #cb1515"
            />
            <span class="text-white p-3 max-w-sm flex items-center ml-20 mb-28"
              >{{ offlineDeviceCount }}
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            Failed Devices
          </p>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 flex"
      >
        <div class="p-3 max-w-sm">
          <div
            class="rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto"
          >
            <font-awesome-icon
              :icon="['fass', 'battery-full']"
              style="color: #308bc8"
            />
            <span class="text-white p-3 max-w-sm flex items-center ml-20 mb-28"
              >{{ newDeviceCount }}
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            New Devices
          </p>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700 flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="rounded-full w-14 h-14 text-lg p-3 text-purple-600 mx-auto"
          >
            <font-awesome-icon
              :icon="['fas', 'triangle-exclamation']"
              style="color: #ff9d14"
            />
            <span class="text-white p-3 max-w-sm flex items-center ml-20 mb-28"
              >6
            </span>
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-900 dark:text-gray-200 text-xl">
            Current Alerts
          </p>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->
    <div class="mt-2 lg:flex block lg:gap-2 relative md-wrap-item">
      <div
        class="bg-white dark:bg-gray-800 p-0 lg:w-3/4 w-full rounded-md box-border border dark:border-gray-700"
      >
        <div ref="mapContainer" class="h-96" style="width: 100%">
          <!-- Status filters within the map container -->
          <div
            class="checkboxes flex flex-col status-filters absolute top-10 left-0 p-4 bg-white"
            style="z-index: 1000"
          >
            <label>
              <input
                type="checkbox"
                v-model="statusFilters.online"
                @change="filterDevices"
              />
              Online
            </label>
            <label>
              <input
                type="checkbox"
                v-model="statusFilters.offline"
                @change="filterDevices"
              />
              Offline
            </label>
            <label>
              <input
                type="checkbox"
                v-model="statusFilters.new"
                @change="filterDevices"
              />
              New
            </label>
          </div>
        </div>
      </div>
      <div
        class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4 lg:w-2/4 border dark:border-gray-700 rounded-md w-full message-container"
      >
        <div class="divide-y h-96 mt-5 dark:divide-gray-700">
          <h3
            class="text-2xl text-gray-900 dark:text-gray-200 font-medium message--title"
          >
            Latest Messages
          </h3>

          <template v-if="messages.length === 0">
            <div class="p-4">
              <p class="text-gray-800 dark:text-white">
                There are no messages available.
              </p>
            </div>
          </template>

          <template v-else>
            <div v-for="message in messages" :key="message.id" class="p-4">
              <p class="text-gray-800 dark:text-white">
                {{ message.messageText }}
              </p>
              <small class="text-gray-500">{{ message.receivedAt }}</small>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="wrapper-card grid lg:grid-cols-1 grid-cols-1 md:grid-cols-1 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div style="width: 100%">
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div
              class="w-full grid-item wrapper-card grid lg:grid-cols-12 grid-cols-1 md:grid-cols-1 gap-2"
            >
              <p
                class="title text-gray-900 dark:text-gray-200 col-span-2"
                style="outline: none"
              >
                Alerts Over Time
              </p>
              <p class="text-gray-900 dark:text-gray-200 col-span-5">
                <!-- <font-awesome-icon :icon="['fas', 'circle']" style="width:10px; color:#008FFB" /> Devices-->
                <dropdown class="full-width select--items">
                  <template v-slot:button>
                    <button
                      class="dropDown py-3 rounded-md bg-transparent text-gray-500 cursor-pointer"
                    >
                      {{ selectedDeviceName }}
                      <span class="ml-5 mt-1"
                        ><Icon icon="ant-design:caret-down-filled"
                      /></span>
                    </button>
                  </template>
                  <template v-slot:content>
                    <div class="">
                      <!-- Render dropdown options dynamically using v-for -->
                      <a
                        @click="selectDevice(device.id)"
                        v-for="device in devicesList"
                        :key="device.id"
                        :value="device.id"
                        class="block hover:bg-primary hover:text-white px-4 py-2 cursor-pointer"
                      >
                        {{ device.name }}
                      </a>
                    </div>
                  </template>
                </dropdown>
              </p>
              <p class="text-gray-900 dark:text-gray-200 col-span-5 filter">
                <span
                  class="cursor-pointer"
                  style="padding-right: 0.5rem"
                  @click="selectTimeFrame('24 hours')"
                  :class="{
                    'selected-filter': selectedTimeFrame === '24 hours',
                  }"
                  >24 Hours</span
                >
                <span
                  class="cursor-pointer"
                  style="padding-right: 0.5rem"
                  @click="selectTimeFrame('7 days')"
                  :class="{ 'selected-filter': selectedTimeFrame === '7 days' }"
                  >7 days</span
                >
                <span
                  class="cursor-pointer"
                  style="padding-right: 0.5rem"
                  @click="selectTimeFrame('30 days')"
                  :class="{
                    'selected-filter': selectedTimeFrame === '30 days',
                  }"
                  >30 days</span
                >
                <span
                  class="cursor-pointer"
                  @click="selectTimeFrame('12 months')"
                  :class="{
                    'selected-filter': selectedTimeFrame === '12 months',
                  }"
                >
                  12 months
                </span>
              </p>
            </div>
            <div
              ref="alertOverTimeChart"
              class="w-full"
              :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";

import Dropdown from "../components/Dropdown.vue";

// @ is an alias to /src
import VueApexCharts from "vue3-apexcharts";
import store, { getMessagesQuery } from "@/store/store";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import greenMarker from "../assets/markers/green-marker64.png";
import redMarker from "../assets/markers/red-marker64.png";
import blueMarker from "../assets/markers/blue-marker64.png";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
} from "@vue-leaflet/vue-leaflet";
import checkToken from "@/jwt";
import getDevices from "@/api/data";
import {
  getDevicesById,
  getMessagesByDeviceIdAndTimeRange,
} from "@/api/apicalls";
import { Icon } from "@iconify/vue";
import { latLng } from "leaflet/src/geo";
import Preloader from "@/components/Preloader.vue";

export default {
  name: "Dashboard",

  data() {
    return {
      alertOverTimeChart: null,
      alertOverTimeChartSeries: [],
      alertOverTimeChartOptions: {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "horizontal", // 'vertical' or 'horizontal'
          left: "right", // Position: 'left', 'center', 'right'
          top: "top", // Position: 'top', 'middle', 'bottom'
          textStyle: {
            color: "#ffffff", // Set legend text color to white
          },
        },
        xAxis: {
          type: "time", // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "Alerts Count", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },
      loading: true,
      timeline: [],
      selectedDeviceName: "Devices",
      timeLineData: [],
      devicesList: [], // Add this line
      selectedTimeFrame: "24 hours",
      selectedDevice: null,
      siteMarkersGroup: null,
      markers: [],
      dataDevices: [],
      zoom: 6.45,
      center: latLng(53.4494762, -7.5029786),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(53.4494762, -7.5029786),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        maxZoom: 18,
        maxBounds: L.latLngBounds(
          L.latLng(18.91619, -171.791110603),
          L.latLng(71.3577635769, -66.96466)
        ),
      },
      showMap: true,
      messages: [],
      // Define custom icon for markers
      redIcon: L.icon({
        iconUrl: redMarker, // Replace with the path to your local image
        iconSize: [40, 40], // Size of the icon
        iconAnchor: [20, 40], // Point of the icon which corresponds to marker's location
        popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
      }),
      greenIcon: L.icon({
        iconUrl: greenMarker, // Replace with the path to your local image
        iconSize: [40, 40], // Size of the icon
        iconAnchor: [20, 40], // Point of the icon which corresponds to marker's location
        popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
      }),
      blueIcon: L.icon({
        iconUrl: blueMarker, // Replace with the path to your local image
        iconSize: [40, 40], // Size of the icon
        iconAnchor: [20, 40], // Point of the icon which corresponds to marker's location
        popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
      }),
      newDeviceCount: 0,
      onlineDeviceCount: 0,
      offlineDeviceCount: 0,
      onlineDevices: 0,
      offlineDevices: 0,
      newDevices: 0,
      mapInitialized: false,
      statusFilters: {
        online: true,
        offline: true,
        new: true,
      },
      devices: [],
      map: null,
      alertsSeries: [],
      alertsInfoCount: 0,
      alertsLowCount: 0,
      alertsHighCount: 0,
      alertsCriticalCount: 0,
    };
  },
  components: {
    Preloader,
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    apexchart: VueApexCharts,
    Dropdown,
    Icon,
  },
  computed: {
    showOnlineDevices() {
      this.filterDevices();
    },
    showOfflineDevices() {
      this.filterDevices();
    },
    showNewDevices() {
      this.filterDevices();
    },
  },
  methods: {
    initAlertOverTimeChart() {
      const chartDom = this.$refs.alertOverTimeChart;
      if (chartDom) this.alertOverTimeChart = echarts.init(chartDom);
      else console.error("Dom alert Over Time Chart Invalid");
    },
    updateAlertOverTimeChart() {
      this.alertOverTimeChartOptions.series = this.alertOverTimeChartSeries;
      if (this.alertOverTimeChart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.alertOverTimeChart);
        const chartDom = this.$refs.alertOverTimeChart;
        const chart = echarts.init(chartDom);

        chart.setOption(this.alertOverTimeChartOptions, false, true);
        this.tempChart = chart;
      } else console.warn("Error in Temp Chart");
    },
    selectTimeFrame(timeFrame) {
      this.selectedTimeFrame = timeFrame;
      this.filterMessagesByDeviceAndTimeFrame();
    },
    selectDevice(deviceId) {
      this.selectedDeviceId = deviceId;
      const device = this.devicesList.find((device) => device.id === deviceId);
      this.selectedDeviceName = device ? device.name : "Devices";

      this.filterMessagesByDeviceAndTimeFrame();
    },
    getCounDays(timeFrame) {
      let countOfDays = 0;
      switch (timeFrame) {
        case "24 hours":
          countOfDays = 1;
          break;
        case "7 days":
          countOfDays = 7;
          break;
        case "30 days":
          countOfDays = 30;
          break;
        case "12 months":
          countOfDays = 365;
          break;
      }
      return countOfDays;
    },
    calculateTimeFrame() {
      const daysCount = this.getCounDays(this.selectedTimeFrame);
      let now = new Date();
      const beginDate = new Date();
      beginDate.setDate(beginDate.getDate() - daysCount);

      const begin = beginDate.toISOString().split(".")[0];
      const end = now.toISOString().split(".")[0];

      return { begin, end };
    },
    async filterMessagesByDeviceAndTimeFrame() {
      const { begin, end } = this.calculateTimeFrame();
      const messages = await getMessagesByDeviceIdAndTimeRange(
        this.$apollo,
        this.$router,
        this.selectedDeviceId,
        begin,
        end
      );

      this.alertOverTimeChartSeries = [];
      this.renderAlertOverTimeChart(messages);
    },
    async getLastMessage(deviceId, type, device) {
      try {
        const data = await this.$apollo.query({
          query: getMessagesQuery.getLatestMessage,
          variables: {
            deviceId: deviceId,
            messageType: type,
          },
        });

        if (
          data?.data?.messages?.messages &&
          data?.data?.messages?.messages.length > 0
        ) {
          let lastMessage = data.data.messages.messages[0];
          let nowDate = new Date();
          let deviceLastSeenDate = new Date(device.lastSeen);
          let lastHeartbeatDate = new Date(lastMessage.receivedAt);
          let lastSeenTimestamp;

          if (deviceLastSeenDate.getTime() > lastHeartbeatDate.getTime()) {
            lastSeenTimestamp = deviceLastSeenDate.getTime();
          } else if (
            lastHeartbeatDate.getTime() > deviceLastSeenDate.getTime()
          ) {
            lastSeenTimestamp = lastHeartbeatDate.getTime();
          }

          let lastSeenStatus;
          if (lastSeenTimestamp < nowDate.getTime() - 660000) {
            lastSeenStatus = "Offline";
          } else {
            lastSeenStatus = "Online";
          }

          let extendedDevice = {
            ...device,
            lastSeenStatus: lastSeenStatus,
          };

          if (
            ["online", "offline"].includes(
              extendedDevice.lastSeenStatus.toLowerCase()
            )
          ) {
            // Find the device in the array
            const deviceIndex = this.dataDevices.findIndex(
              (d) => d.id === device.id
            );

            if (deviceIndex !== -1) {
              // Update the lastSeenStatus if the device is found
              this.dataDevices[deviceIndex].lastSeenStatus = lastSeenStatus;
            } else {
              // Push the device to the array if not found
              this.dataDevices.push(extendedDevice);
            }
          }
        }
      } catch (error) {
        console.error("Error in Operator Analytics get messages => ", error);
      }
    },
    async initDevicesData(userId, userType) {
      this.loading = true;
      try {
        let messages = true;
        const devicesData = await getDevices(
          this.$apollo,
          this.$router,
          userId,
          userType,
          messages
        );

        // Extract device names and IDs and assign them to devicesList To Fill The Drop Down List
        this.devicesList = devicesData.map((device) => ({
          id: device.id,
          name: device.name,
        }));

        let devices = [];
        for (const device of devicesData) {
          if (device) {
            device.messages?.edges?.forEach((message) => {
              let messageData = message.node;
              this.messages.push(messageData);
            });
            // Sort messages by Received At field
            this.messages.sort((a, b) => {
              return new Date(b.receivedAt) - new Date(a.receivedAt);
            });

            // Get the latest 10 messages
            this.messages = this.messages.slice(0, 10);

            //Initialise Status
            let nowDate = new Date();
            let lastSeenTimestamp;

            if (device.lastSeen) {
              let deviceLastSeenDate = new Date(device.lastSeen);
              lastSeenTimestamp = deviceLastSeenDate.getTime();
            }

            let lastSeenStatus;
            if (lastSeenTimestamp) {
              if (lastSeenTimestamp < nowDate.getTime() - 660000) {
                lastSeenStatus = "Offline";
              } else {
                lastSeenStatus = "Online";
              }
            } else {
              lastSeenStatus = "Offline";
            }

            let extendedDevice = {
              ...device,
              lastSeenStatus: lastSeenStatus,
            };

            this.dataDevices.push(extendedDevice);

            await this.getLastMessage(device.id, "Heartbeat", device);

            // Find the device in the array
            const deviceIndex = this.dataDevices.findIndex(
              (d) => d.id === device.id
            );

            if (deviceIndex !== -1) {
              if (this.isDeviceNew(this.dataDevices[deviceIndex])) {
                this.dataDevices[deviceIndex].lastSeenStatus = "New";
              }

              //Dash Counts
              switch (this.dataDevices[deviceIndex].lastSeenStatus) {
                case "Online":
                  this.onlineDeviceCount++;
                  break;
                case "Offline":
                  this.offlineDeviceCount++;
                  break;
                case "New":
                  this.newDeviceCount++;
                  break;
              }
            }
          }
        }

        //initialize Chart
        if (devicesData.length > 0) {
          // Check if there is at least one device in the devicesData array
          this.selectedDeviceId = devicesData[0].id; // Set the selected device to the first device's id
          this.selectedDeviceName = devicesData[0].name;
          await this.filterMessagesByDeviceAndTimeFrame();
        }
      } catch (error) {
        this.loading = false;
        console.error("Error in dashboard - initialize Devices Data: ", error);
      }
    },
    filterDevices() {
      // Step 1: Get the selected status filters
      const { online, offline, new: isNew } = this.statusFilters;
      // Step 2: Filter devices based on the selected status filters
      this.filtredDevices = this.dataDevices.filter((device) => {
        return (
          (isNew && device.lastSeenStatus.toLowerCase() === "new") ||
          (online && device.lastSeenStatus.toLowerCase() === "online") ||
          (offline && device.lastSeenStatus.toLowerCase() === "offline")
        );
      });
      this.clearMarkers(); // Clear existing markers before adding new ones
      this.addMarkers(this.filtredDevices);
    },
    initializeMap() {
      this.map = L.map(this.$refs.mapContainer).setView(
        [this.center.lat, this.center.lng],
        this.zoom
      );
      L.tileLayer(this.url, { attribution: this.attribution }).addTo(this.map);
    },
    clearMarkers() {
      if (this.markers) {
        this.markers.clearLayers();
      }
    },
    addMarkers(devices) {
      if (!devices || devices.length === 0) {
        return;
      }

      this.markers = L.markerClusterGroup({});

      devices.forEach((device) => {
        let markerIcon;
        if (device.lastSeenStatus.toLowerCase() === "online") {
          markerIcon = this.greenIcon;
        } else if (device.lastSeenStatus.toLowerCase() === "offline") {
          markerIcon = this.redIcon;
        } else if (device.lastSeenStatus.toLowerCase() === "new") {
          markerIcon = this.blueIcon;
        }

        // Create markers using custom icon
        const marker = L.marker([device.gpsLat, device.gpsLong], {
          icon: markerIcon,
        }).bindPopup(
            `<b>${device.name}</b><br>Status: ${device.lastSeenStatus}<br>Coordinates: (${device.gpsLat}, ${device.gpsLong})`
        );

        this.markers.addLayer(marker);
      });
      this.map.addLayer(this.markers);
    },
    isDeviceNew(device) {
      const currentTime = new Date();
      const dateCreate = new Date(device.insertedAt);

      // Convert current time and device creation time to UTC
      const currentUTCTime = Date.UTC(
        currentTime.getUTCFullYear(),
        currentTime.getUTCMonth(),
        currentTime.getUTCDate(),
        currentTime.getUTCHours(),
        currentTime.getUTCMinutes(),
        currentTime.getUTCSeconds(),
        currentTime.getUTCMilliseconds()
      );

      const deviceUTCTime = Date.UTC(
        dateCreate.getUTCFullYear(),
        dateCreate.getUTCMonth(),
        dateCreate.getUTCDate(),
        dateCreate.getUTCHours(),
        dateCreate.getUTCMinutes(),
        dateCreate.getUTCSeconds(),
        dateCreate.getUTCMilliseconds()
      );
      // Check if the time difference is less than 1 hour and device status is online
      const isNewDevice =
        currentUTCTime - deviceUTCTime < 1 * 60 * 60 * 1000 &&
        device.lastSeenStatus.toLowerCase() === "online";
      return isNewDevice;
    },
    renderAlertOverTimeChart(messages) {
      if (messages?.length > 0) {
        //Sort messages by thier recieving date
        messages.sort(
          (a, b) => new Date(a.receivedAt) - new Date(b.receivedAt)
        );

        //get time frame beging and end
        const { begin, end } = this.calculateTimeFrame();
        const beginDate = new Date(begin);
        const endDate = new Date(end);

        // Initialize arrays to store counts for each severity
        let countsInfo = [];
        let countsLow = [];
        let countsHigh = [];
        let countsCritical = [];

        // Initialize and Generate the timeline array
        let timeline = [];
        for (
          let iDate = beginDate.getTime() + 2 * 60 * 60 * 1000;
          iDate <= endDate.getTime() + 60 * 60 * 1000;
          iDate += 60 * 60 * 1000
        ) {
          let iDateTime = new Date(iDate);
          // Set the minutes, seconds, and milliseconds to 0 to normalize the date
          iDateTime.setMinutes(0, 0, 0);

          // Convert the normalized date to ISO string
          let iISODateTime = iDateTime.toISOString();
          timeline.push(iISODateTime);
          countsInfo.push({ x: iISODateTime, y: 0 });
          countsLow.push({ x: iISODateTime, y: 0 });
          countsHigh.push({ x: iISODateTime, y: 0 });
          countsCritical.push({ x: iISODateTime, y: 0 });
        }

        messages.forEach((message) => {
          // Create a new Date object from the message's received date
          let messageDate = new Date(message.receivedAt);
          // Set the minutes, seconds, and milliseconds to 0 to normalize the date
          messageDate.setMinutes(0, 0, 0);

          // Convert the normalized date to ISO string
          let dateTimeStr = messageDate.toISOString();

          // Find the index of the normalized date in the timeline array
          let indice = timeline.indexOf(dateTimeStr);

          // Increment the count for the found index
          if (indice !== -1) {
            switch (message.severity) {
              case "Info":
                countsInfo[indice].y++;
                break;
              case "Low":
                countsLow[indice].y++;
                break;
              case "High":
                countsHigh[indice].y++;
                break;
              case "Critical":
                countsCritical[indice].y++;
                break;
            }
          }
        });

        // Populate alertsSeries with the counts
        this.alertOverTimeChartSeries = [
          {
            name: "Info",
            type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
            color: "#008FFB",
            data: countsInfo.map((item) => [new Date(item.x), item.y]),
            smooth: true, // Smooth the line
          },
          {
            name: "Low",
            type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
            color: "#00E396FF",
            data: countsLow.map((item) => [new Date(item.x), item.y]),
            smooth: true, // Smooth the line
          },
          {
            name: "High",
            type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
            color: "#FEB019FF",
            data: countsHigh.map((item) => [new Date(item.x), item.y]),
            smooth: true, // Smooth the line
          },
          {
            name: "Critical",
            type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
            color: "#FF4560FF",
            data: countsCritical.map((item) => [new Date(item.x), item.y]),
            smooth: true, // Smooth the line
          },
        ];
      }
      this.updateAlertOverTimeChart();
      this.loading = false;
    },
    utcDate(sourceDate) {
      return Date.UTC(
        sourceDate.getUTCFullYear(),
        sourceDate.getUTCMonth(),
        sourceDate.getUTCDate(),
        sourceDate.getUTCHours(),
        sourceDate.getUTCMinutes(),
        sourceDate.getUTCSeconds(),
        sourceDate.getUTCMilliseconds()
      );
    },
  },
  async mounted() {
    this.loading = true;
    await this.$nextTick(async () => {
      this.initializeMap();
      this.initAlertOverTimeChart();
      if (store.getters.getUser) {
        let user = store.getters.getUser;
        let userId = user.id;
        let userType = user.type;
        await this.initDevicesData(userId, userType);
      }

      //initialize Map with markers
      this.addMarkers(this.dataDevices);
    });
    this.loading = false;
  },
  watch: {
    messages(newMessages) {
      // This watch will trigger when messages are added or changed
      this.messages.push(newMessages);
    },
  },
};
</script>

<style>
.checkboxes {
  position: relative;
  top: 15rem;
  margin-left: 1rem;
  margin-top: 4rem;
}

.message-container {
  overflow-y: scroll;
  position: relative !important;
}

.message-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.message-container::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.message-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #464545d3;
}

.grid-item {
  width: 100%;
}

.grid-item p {
  outline: 1px solid;
  border-radius: 20px;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9em;
}

.filter span {
  cursor: pointer;
  transition: all 0.5ms ease-in-out;
  padding: 0.1rem 1.2rem;
}

.selected-filter {
  position: relative;
  cursor: pointer;
}

.selected-filter {
  background-color: #151327;
  border-radius: 20px;
}

.dropDown {
  width: 377px;
  display: grid;
  grid-template-columns: repeat(2, 90% 20px);
}

.select--items > div > div {
  width: 300px;
}
</style>
