<template>
  <Preloader v-if="loading" />

  <div class="alerts__container mt-6 mb-6 mx-6 px-4">
    <!-- end nav -->
    <div class="mt-2 w-full">
      <div
        class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between"
      >
        <div>
          <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
            Analytics
          </h1>
        </div>
        <div class="flex gap-2"></div>
      </div>
    </div>
    <div class="flex gap-3 controls">
      <input
        class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
        style="background-color: #02ff00; color: black"
        v-model="selectedDate"
        type="date"
        id="date"
        name="date"
        @change="changeDate"
      />
      <div class="flex items-center custom--grid--layout">
        <select
          v-model="selectedDeviceId"
          @change="filterByDevice"
          class="drop-down p-2 rounded-md text-sm font-medium text-gray-700"
          style="background-color: #02ff00; color: black"
        >
          <option value="">All Devices</option>
          <option v-for="device in devices" :key="device.id" :value="device.id">
            {{ device.name }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="first-row wrapper-card grid lg:grid-cols-1 grid-cols-1 md:grid-cols-1 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="card device-temp bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div>
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div class="block w-full">
              <p class="text-gray-900 dark:text-gray-200 text-center">
                Device Temperature
              </p>
            </div>
            <div
              ref="tempchart"
              class="w-full"
              :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->
    <div
      class="second-row wrapper-card grid lg:grid-cols-2 grid-cols-1 md:grid-cols-1 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="battery-failure-card card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div>
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div class="block w-full">
              <p class="text-gray-900 dark:text-gray-200">
                Battery Charge Status
              </p>
            </div>
            <div
                ref="batteryChargeChart"
                class="w-full"
                :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
      <!-- card  -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div>
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div class="block w-full">
              <p class="text-gray-900 dark:text-gray-200">Power Usage</p>
            </div>
            <div
              ref="powerOverTimeChart"
              class="w-full"
              :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <div
      class="second-row wrapper-card grid lg:grid-cols-1 grid-cols-1 md:grid-cols-1 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="battery-failure-card card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div>
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div class="block w-full">
              <p class="text-gray-900 dark:text-gray-200">
                Battery Failure Prediction
              </p>
            </div>
            <div
              ref="batteryPredictionchart"
              class="w-full"
              :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <div
      class="second-row wrapper-card grid lg:grid-cols-1 grid-cols-1 md:grid-cols-1 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="battery-failure-card card bg-white dark:bg-gray-800 w-full rounded-md p-5 border dark:border-gray-700"
      >
        <div>
          <div class="w-full text-lg text-orange-600 mx-auto">
            <div class="block w-full">
              <p class="text-gray-900 dark:text-gray-200">
                Intrusion Detection
              </p>
            </div>
            <div
              ref="intrusionDetectionChart"
              class="w-full"
              :style="{ height: '300px' }"
            ></div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import store, { getDevicesQueries, getMessagesQuery } from "@/store/store";
import Preloader from "@/components/Preloader.vue";

export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DataTable,
    Column,
  },
  data() {
    return {
      loading: true,
      selectedDevice: "Online Devices",
      selectedDate: new Date(),
      metersData: [],

      tempChart: null,
      tempSeries: [],
      tempChartOptions: {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "vertical", // 'vertical' or 'horizontal'
          left: "right", // Position: 'left', 'center', 'right'
          top: "top", // Position: 'top', 'middle', 'bottom'
          textStyle: {
            color: "#ffffff", // Set legend text color to white
          },
        },
        xAxis: {
          type: "time", // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "Temperature (°C)", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },
      batteryChargeChart: null,
      batteryChargeChartSeries: [],
      batteryChargeChartOptions: {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "time", // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "State of Charge (%)", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },
      batteryPredictionChart: null,
      batteryPredictionChartSeries: [],
      batteryPredictionChartOptions: {
        title: {},
        legend: {
          orient: "vertical", // 'vertical' or 'horizontal'
          left: "right", // Position: 'left', 'center', 'right'
          top: "top", // Position: 'top', 'middle', 'bottom'
          textStyle: {
            color: "#ffffff", // Set legend text color to white
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "time", // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "SOH / SOC (%)", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },
      powerOverTimeChart: null,
      powerOverTimeChartSeries: [],
      powerOverTimeChartOptions: {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "vertical", // 'vertical' or 'horizontal'
          left: "right", // Position: 'left', 'center', 'right'
          top: "top", // Position: 'top', 'middle', 'bottom'
          textStyle: {
            color: "#ffffff", // Set legend text color to white
          },
        },
        xAxis: {
          type: "time", // Use 'time' for datetime x-axis
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "Power (KW)", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },
      intrusionDetectionChart: null,
      intrusionDetectionChartSeries: [],
      intrusionDetectionChartOptions: {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", //we use type category to add xAxis static timeline
          data: [], //list of static timeline
          axisLabel: {
            showEmpty: false, // Hide labels when no data is available
            color: "white", // Set the color of xAxis labels to white
          },
        },
        yAxis: {
          name: "Number of Alerts", // Title for the y-axis
          nameTextStyle: {
            color: "white", // Set the color of yAxis title to white
            verticalAlign: "middle", // Align title vertically
          },
          axisLabel: {
            color: "white", // Set the color of yAxis labels to white
          },
          splitLine: {
            show: false,
          },
        },
        toolbox: {
          left: "center",
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
          },
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            xAxisIndex: [0],
            filterMode: "filter",
          },
        ],
        series: [],
      },

      DevicesData: [],
      userId: null,
      batteryPredictionData: [],
      devices: [],
      selectedDeviceId: "",
    };
  },
  created() {
    // Set the selectedDate to the current date in YYYY-MM-DD format
    const today = new Date();
    today.setDate(today.getDate() - 1);

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    this.selectedDate = `${year}-${month}-${day}`;
  },
  methods: {
    initTempChart() {
      const chartDom = this.$refs.tempchart;
      if (chartDom) this.tempChart = echarts.init(chartDom);
      else console.error("Dom Temp Chart Invalid");
    },
    initBatteryChargeChart() {
      const chartDom = this.$refs.batteryChargeChart;
      if (chartDom) this.batteryChargeChart = echarts.init(chartDom);
      else console.error("Dom battery Charge Chart Invalid");
    },
    initBatteryPredictionChart() {
      const chartDom = this.$refs.batteryPredictionchart;
      if (chartDom) this.batteryPredictionchart = echarts.init(chartDom);
      else console.error("Dom battery Prediction Chart Invalid");
    },
    initPowerOverTimeChart() {
      const chartDom = this.$refs.powerOverTimeChart;
      if (chartDom) this.powerOverTimeChart = echarts.init(chartDom);
      else console.error("Dom power Over Time Chart Invalid");
    },
    initIntrusionDetectionChart() {
      const chartDom = this.$refs.intrusionDetectionChart;
      if (chartDom) this.intrusionDetectionChart = echarts.init(chartDom);
      else console.error("Dom Intrusion Detection Chart Invalid");
    },
    initCharts() {
      this.initTempChart();
      this.initBatteryChargeChart();
      this.initBatteryPredictionChart();
      this.initPowerOverTimeChart();
      this.initIntrusionDetectionChart();
    },
    /**** Update Chart ******/
    updateTempChart() {
      this.tempChartOptions.series = this.tempSeries;
      if (this.tempChart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.tempchart);
        const chartDom = this.$refs.tempchart;
        const chart = echarts.init(chartDom);

        // Set the graphic option for "No Data Available"
        this.tempChartOptions.graphic = {
          type: "text",
          left: "center",
          top: "middle",
          style: {
            text:
              this.tempSeries.length !== 0 &&
              this.tempSeries[0].data.length !== 0
                ? ""
                : "No Data Available",
            fill: "#02FF00", // Set text color to bright green
            fontSize: 18,
            fontWeight: "bold",
          },
        };

        chart.setOption(this.tempChartOptions, false, true);
        this.tempChart = chart;
      } else console.warn("Error in Temp Chart");
    },
    updateBatteryChargeChart() {
      this.batteryChargeChartOptions.series = this.batteryChargeChartSeries;
      if (this.batteryChargeChart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.batteryChargeChart);
        const chartDom = this.$refs.batteryChargeChart;
        const chart = echarts.init(chartDom);

        // Set the graphic option for "No Data Available"
        this.batteryChargeChartOptions.graphic = {
          type: "text",
          left: "center",
          top: "middle",
          style: {
            text:
              this.batteryChargeChartSeries.length !== 0 &&
              this.batteryChargeChartSeries[0].data.length !== 0
                ? ""
                : "No Data Available",
            fill: "#02FF00", // Set text color to bright green
            fontSize: 18,
            fontWeight: "bold",
          },
        };

        chart.setOption(this.batteryChargeChartOptions, false, true);
        this.batteryChargeChart = chart;
      } else console.warn("Error in battery Charge Chart");
    },
    updateBatteryPredictionChart() {
      this.batteryPredictionChartOptions.series =
        this.batteryPredictionChartSeries;
      if (this.batteryPredictionchart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.batteryPredictionchart);
        const chartDom = this.$refs.batteryPredictionchart;
        const chart = echarts.init(chartDom);

        // Set the graphic option for "No Data Available"
        this.batteryPredictionChartOptions.graphic = {
          type: "text",
          left: "center",
          top: "middle",
          style: {
            text:
              this.batteryPredictionChartSeries.length === 2 &&
              (this.batteryPredictionChartSeries[0].data.length !== 0 ||
                this.batteryPredictionChartSeries[1].data.length !== 0)
                ? ""
                : "No Data Available",
            fill: "#02FF00", // Set text color to bright green
            fontSize: 18,
            fontWeight: "bold",
          },
        };

        chart.setOption(this.batteryPredictionChartOptions, false, true);
        this.batteryPredictionchart = chart;
      } else console.warn("Error in battery Prediction Chart");
    },
    updatePowerOverTimeChart() {
      this.powerOverTimeChartOptions.series = this.powerOverTimeChartSeries;
      if (this.powerOverTimeChart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.powerOverTimeChart);
        const chartDom = this.$refs.powerOverTimeChart;
        const chart = echarts.init(chartDom);

        // Set the graphic option for "No Data Available"
        this.powerOverTimeChartOptions.graphic = {
          type: "text",
          left: "center",
          top: "middle",
          style: {
            text:
              this.powerOverTimeChartSeries.length === 2 &&
              (this.powerOverTimeChartSeries[0].data.length !== 0 ||
                this.powerOverTimeChartSeries[1].data.length !== 0)
                ? ""
                : "No Data Available",
            fill: "#02FF00", // Set text color to bright green
            fontSize: 18,
            fontWeight: "bold",
          },
        };

        chart.setOption(this.powerOverTimeChartOptions, false, true);
        this.powerOverTimeChart = chart;
      } else console.warn("Error in power Over Time Chart");
    },
    updateIntrusionDetectionChart() {
      this.intrusionDetectionChartOptions.series =
        this.intrusionDetectionChartSeries;
      if (this.intrusionDetectionChart) {
        //Destroy chart object and re-initialise the Chart Object.
        echarts.dispose(this.$refs.intrusionDetectionChart);
        const chartDom = this.$refs.intrusionDetectionChart;
        const chart = echarts.init(chartDom);

        // Set the graphic option for "No Data Available"
        this.intrusionDetectionChartOptions.graphic = {
          type: "text",
          left: "center",
          top: "middle",
          style: {
            text:
              this.intrusionDetectionChartSeries.length === 4 &&
              (this.intrusionDetectionChartSeries[0].data.length !== 0 ||
                this.intrusionDetectionChartSeries[1].data.length !== 0 ||
                this.intrusionDetectionChartSeries[2].data.length !== 0 ||
                this.intrusionDetectionChartSeries[3].data.length !== 0)
                ? ""
                : "No Data Available",
            fill: "#02FF00", // Set text color to bright green
            fontSize: 18,
            fontWeight: "bold",
          },
        };

        chart.setOption(this.intrusionDetectionChartOptions, false, true);
        this.intrusionDetectionChart = chart;
      } else console.warn("Error in Intrusion Detection Chart");
    },

    changeDate() {
      this.filterByDevice();
    },
    renderPowerOverTimeChart(messages) {
      let powerIn = [];
      let powerOut = [];

      messages.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      messages.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText);
        powerOut.push({
          x: new Date(message.receivedAt).getTime(),
          y: parsedMessage.pwrOut,
        });
        powerIn.push({
          x: new Date(message.receivedAt).getTime(),
          y: parsedMessage.pwrIn,
        });
      });

      this.powerOverTimeChartSeries = [
        {
          name: "Mains Power (KW)",
          type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
          data: powerIn.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
          smooth: true, // Smooth the line
          color: "#f44336",
        },
        {
          name: "Battery Power (KW)",
          type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
          data: powerOut.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
          smooth: true, // Smooth the line
          color: "#00dfff",
        },
      ];
      this.updatePowerOverTimeChart();
    },
    renderBatteryFailurePredictionChart(messages, deviceName) {
      let soh = [];
      let soc = [];

      // Convert and store messages in array messagesData and sort them
      let messagesData = Array.isArray(messages) ? [...messages] : [messages];
      messagesData.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      messagesData.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText);
        if (parsedMessage.battery_soc) {
          soc.push({
            x: new Date(message.receivedAt).getTime(),
            y: parsedMessage.battery_soc.toString().includes("%")
              ? parsedMessage.battery_soc.replace("%", "")
              : parsedMessage.battery_soc,
          });
        }

        if (parsedMessage.battery_soh) {
          soh.push({
            x: new Date(message.receivedAt).getTime(),
            y: parsedMessage.battery_soh.toString().includes("%")
              ? parsedMessage.battery_soh.replace("%", "")
              : parsedMessage.battery_soh,
          });
        }
      });

      this.batteryPredictionChartSeries = [
        {
          name: "Battery SOH (%)",
          type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
          data: soh.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
          smooth: true, // Smooth the line
          markLine: {
            data: [
              {
                yAxis: 40, // Annotation at y = 20
                label: {
                  color: "#fff",
                  backgroundColor: "#FF4560",
                  borderColor: "#FF4560",
                  borderWidth: 1,
                  padding: [5, 10],
                  formatter: "SOH Threshold",
                  offset: [-130, -20], // Add margin from the right edge of the chart
                },
                lineStyle: {
                  color: "#FF4560",
                  type: "solid",
                  width: 2,
                },
              },
            ],
          }
        },
        {
          name: "Battery SOC (%)",
          type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
          data: soc.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
          smooth: true, // Smooth the line
        },
      ];

      this.updateBatteryPredictionChart();

      this.batteryChargeChartSeries = [
        {
          name: "Power Charge (%)",
          type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
          data: soc.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
          smooth: true, // Smooth the line
          color: "#00dfff",
        },
      ];
      this.updateBatteryChargeChart();
    },
    renderTemperatureChart(messages, deviceName) {
      let temp = [];

      // Convert and store messages in array messagesData and sort them
      let messagesData = Array.isArray(messages) ? [...messages] : [messages];
      messagesData.sort(function (a, b) {
        if (a.receivedAt > b.receivedAt) return 1;
        if (a.receivedAt < b.receivedAt) return -1;
        return 0;
      });

      messagesData.forEach((message) => {
        let parsedMessage = JSON.parse(message.messageText);
        if (parsedMessage.battery_temperature) {
          temp.push({
            x: new Date(message.receivedAt).getTime(),
            y: parsedMessage.battery_temperature,
          });
        }
      });

      //temp Series Creation
      const tempSerie = {
        name: deviceName,
        type: "line", // Type of chart (e.g., 'line', 'bar', etc.)
        data: temp.map((item) => [new Date(item.x), item.y]), // Map to [datetime, value] format
        smooth: true, // Smooth the line
      };

      this.tempSeries.push(tempSerie);
      this.updateTempChart();
    },
    renderIntrusionDetectionChart(messages) {
      if (messages && messages.length > 0) {
        // Initialize arrays to store the count of alerts for each severity level
        let criticalAlerts = new Array(24).fill(0);
        let mediumAlerts = new Array(24).fill(0);
        let lowAlerts = new Array(24).fill(0);
        let infoAlerts = new Array(24).fill(0);
        let otherAlerts = new Array(24).fill(0);

        this.intrusionDetectionChartOptions.xAxis.data.length = 0;
        let categories = this.calculate24Hours();
        categories.reverse().forEach((cat) => {
          this.intrusionDetectionChartOptions.xAxis.data.push(cat.displayDate);
        });

        messages.forEach((message) => {
          for (let i = 0; i < 24; i++) {
            if (message.receivedAt.includes(categories[i].date)) {
              switch (message.severity) {
                case "Critical":
                  criticalAlerts[i]++;
                  break;
                case "Medium":
                  mediumAlerts[i]++;
                  break;
                case "Low":
                  lowAlerts[i]++;
                  break;
                case "Info":
                  infoAlerts[i]++;
                  break;
                default:
                  otherAlerts[i]++;
                  break;
              }
            }
          }
        });

        this.intrusionDetectionSeries = [
          { name: "Critical", data: criticalAlerts, color: "#FF0000" },
          { name: "Medium", data: mediumAlerts, color: "#FFA500" },
          { name: "Low", data: lowAlerts, color: "#FFFF00" },
          { name: "Info", data: infoAlerts, color: "#008FFB" },
        ];
        this.updateIntrusionDetectionChart();
      } else this.intrusionDetectionSeries = [];
    },
    getDevicesById() {
      this.$apollo
        .query({
          query: getDevicesQueries.getDevicesByOperatorQuery,
          variables: {
            operatorId: this.userId,
          },
        })
        .then(async (data) => {
          this.devices = data.data.devices.devices;
          this.selectedDeviceId = this.devices[0].id;
          await this.filterByDevice();
        })
        .catch((error) => {
          // Error
          this.loading = false; // Stop loading on error
          console.error("Error in login getUsers query => ", error);
        });
      this.loading = false; // Stop loading on error
    },
    async getLast24HoursMessages(deviceId, type, begin, end, deviceName) {
      try {
        const response = await this.$apollo.query({
          query: getMessagesQuery.getLast24HoursMessages,
          variables: {
            deviceId: deviceId,
            messageType: type,
            begin: begin,
            end: end,
          },
        });

        if (response?.data?.messages?.messages) {
          const messages = response.data.messages.messages;
          switch (type) {
            case "BMS":
              this.tempSeries = [];
              this.renderBatteryFailurePredictionChart(messages, deviceName);
              this.renderTemperatureChart(messages, deviceName);
              break;
            case "Meter":
              this.renderPowerOverTimeChart(messages);
              break;
            case "IDS":
              this.renderIntrusionDetectionChart(messages);
              break;
            default:
              console.error(`Unhandled message type: ${type}`);
          }
        }
      } catch (error) {
        console.error("Error in Operator Analytics get messages => ", error);
      }
    },
    async getLast24HoursBMSMessages(deviceId, begin, end) {
      try {
        const { data } = await this.$apollo.query({
          query: getMessagesQuery.getLast24HoursMessages,
          variables: {
            deviceId: deviceId,
            messageType: "BMS",
            begin: begin,
            end: end,
          },
        });

        if (data && data.messages && data.messages.messages) {
          return data.messages.messages;
        } else {
          console.warn("No messages found");
          return [];
        }
      } catch (error) {
        console.error(
          "Error in Operator Analytics getLast24HoursBMSMessages => ",
          error
        );
        return [];
      }
    },
    calculate24Hours() {
      let today = new Date();
      let last24 = [];
      for (let i = 0; i < 24; i++) {
        let date = new Date(today.getTime() - 3600000 * i);

        let localString = date.toISOString().substring(0, 14);
        let hour = date.getHours();

        let dateForChart = {
          displayDate: hour + ":00",
          date: localString,
        };

        last24.push(dateForChart);
      }
      return last24;
    },
    async filterByDevice() {
      this.loading = true;
      const selectedDevice = this.devices.find(
        (device) => device.id === this.selectedDeviceId
      );
      let now = new Date();
      let selectedDateObject = new Date(this.selectedDate);
      selectedDateObject.setHours(now.getHours());
      selectedDateObject.setMinutes(now.getMinutes());
      selectedDateObject.setSeconds(now.getSeconds());

      let start = selectedDateObject.toISOString().split(".")[0]; //Start is selected date
      let end = now.toISOString().split(".")[0];


      //check if a device Selected
      if (selectedDevice) {
        await this.getLast24HoursMessages(
          this.selectedDeviceId,
          "BMS",
          start,
          end,
          selectedDevice.name
        );
        await this.getLast24HoursMessages(
          this.selectedDeviceId,
          "Meter",
          start,
          end,
          selectedDevice.name
        );
        await this.getLast24HoursMessages(
          this.selectedDeviceId,
          "IDS",
          start,
          end,
          selectedDevice.name
        );
      } else {
        // in case of 'All Devices' selected
        await this.renderTemperatureChartAllDevices(start, end);
      }
      this.loading = false;
    },
    async renderTemperatureChartAllDevices(start, end) {
      this.tempSeries = [];
      //Show Line forEach device
      for (const device of this.devices) {
        //get messages for each Device
        let messages = await this.getLast24HoursBMSMessages(
          device.id,
          start,
          end
        );
        //render line of each device
        this.renderTemperatureChart(messages, device.name);
      }
    },
  },
  mounted() {
    if (store.getters.getUser) {
      let user = store.getters.getUser;
      let userId = user.id;
      this.userId = userId;
    }
    this.$nextTick(() => {
      this.initCharts();
      this.getDevicesById();
      //this.filterByDevice();
      window.dispatchEvent(new Event("resize"));
    });
  },
};
</script>
<style scoped>
.drop-down:focus {
  outline: none !important;
  box-shadow: none !important;
}

.battery-item {
  width: 191px;
  height: 126px;
  background: linear-gradient(to right, #222222 0%, #2e2d2d 50%, #444343 100%);
  border-radius: 20px;
  position: relative;
}

.battery-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 95px;
  height: 75px;
  background-color: #1d51fe;
  filter: blur(10px);
  transform: scaleX(1.5), translateY(-50%);
  transform-origin: left;
  opacity: 0.6;
}

.battery-item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  height: 50px;
  width: 15px;
  background-color: #393d41;
  z-index: 9999;
  border-radius: 20px;
}

.battery-percentage {
  color: white;
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
}

.battery-text {
  color: white;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .p-datatable .p-datatable-thead > tr .p-column-title {
  font-size: 0.8em !important;
  width: 90px;
}

::v-deep .security-alert-table .p-datatable-wrapper {
  width: 100% !important;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 300px !important;
}

::v-deep .security-alert-table .p-datatable-wrapper {
  max-width: initial !important;
  min-width: initial !important;
  transition: all 5ms ease-in-out;
}

::v-deep .analytics-table .p-datatable-wrapper {
  width: 480px !important;
}

.first-row {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
}

.device-temp {
  grid-column: 1 / span 8;
}

.energy-card {
  grid-column: 9/13;
}

.custom--grid--layout {
  padding: 0 !important;
}

.custom--grid--layout > * {
  margin: 0 !important;
  height: 100%;
}

@media screen and (max-width: 1349px) and (min-width: 300px) {
  ::v-deep .first-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 620px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 680px !important;
  }
}

@media screen and (max-width: 950px) and (min-width: 300px) {
  ::v-deep .first-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 720px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 720px !important;
  }
}

@media screen and (max-width: 850px) and (min-width: 300px) {
  ::v-deep .first-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 520px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 550px !important;
  }
}

@media screen and (max-width: 750px) and (min-width: 300px) {
  ::v-deep .first-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .h-auto {
    height: 220px;
  }

  .battery-failure-card,
  .security-devices-card {
    width: 100%;
    overflow-x: hidden;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 470px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 470px !important;
  }
}

@media screen and (max-width: 550px) and (min-width: 300px) {
  ::v-deep .first-row {
    display: flex !important;
    flex-direction: column !important;
  }

  ::v-deep .second-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .h-auto {
    height: 220px;
  }

  .battery-failure-card,
  .security-devices-card {
    width: 100%;
    overflow-x: hidden;
  }

  ::v-deep .analytics-table .p-datatable-wrapper {
    width: 370px !important;
  }

  ::v-deep .security-alert-table .p-datatable-wrapper {
    max-width: initial !important;
    min-width: initial !important;
    width: 370px !important;
  }
}
</style>
